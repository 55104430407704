import ReactModal from 'react-modal';
import { useCustomScrollShadows } from '../../hooks/customScrollShadows';
import { theme } from '../../theme/theme';
import { V3IconClose } from '../Icons';
import { V3Title } from '../Typography';
import { VerticalSpacing } from '../VerticalSpacing';
import { StyledModal, V3ModalChildrenWrapper, V3ModalCloseWrapper, V3ModalLayout, V3ModalTitleWrapper } from './V3ModalBase.styles';
import { usePreventScroll } from '@react-aria/overlays';
import { useEffect, useState } from 'react';
import { throttle } from 'lodash';

let visualViewport = typeof document !== 'undefined' && window.visualViewport;

const getViewportSize = () => {
    return {
        width: (visualViewport && visualViewport?.width) || window.innerWidth,
        height: (visualViewport && visualViewport?.height) || window.innerHeight
    };
}

interface V3ModalBaseProps {
    isOpen: boolean,
    onClose: () => void,
    title?: string,
    width?: string,
    useHalfContentSplit?: boolean,
    customHalfSplit?: string,
    bottomPanel?: JSX.Element,
    children?: React.ReactNode;
    customPadding?: string,
    hideCloseCTA?: boolean,
}

export const V3ModalBase: React.FC<React.PropsWithChildren<V3ModalBaseProps>> = ({ isOpen, onClose, children, title, width, useHalfContentSplit, customHalfSplit, bottomPanel, customPadding, hideCloseCTA = false }) => {
    const hasTitle = !!title?.length;
    const { onScroll, ref, style } = useCustomScrollShadows();
    const [localHeight, setLocalHeight] = useState(0);

    useEffect(() => {
        let onResize = () => {
            const height = getViewportSize().height;
            console.log("->", height)
            setLocalHeight(height);
        };

        const throttleResize = throttle(onResize, 100);

        throttleResize();

        if (!visualViewport) {
            window.addEventListener('resize', throttleResize);
        } else {
            visualViewport.addEventListener('resize', throttleResize);
        }

        return () => {
            if (!visualViewport) {
                window.removeEventListener('resize', throttleResize);
            } else {
                visualViewport.removeEventListener('resize', throttleResize);
            }
        };
    }, []);

    usePreventScroll({
        isDisabled: !isOpen
    })

    const modalOverlay: ReactModal.Styles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 10,
            height: localHeight + "px",
            backgroundColor: 'rgba(0, 0, 0, 0.45)'
        },
        content: {
            maxHeight: localHeight,
        }
    };

    if (localHeight === 0) return <></>;

    return <StyledModal
        isOpen={isOpen}
        onRequestClose={onClose}
        bodyOpenClassName={null}
        style={modalOverlay as ReactModal.Styles}
        ariaHideApp={false}
        //@ts-ignore
        width={width}
        useHalfContentSplit={useHalfContentSplit}
        customHalfSplit={customHalfSplit}
    >
        <V3ModalLayout $customPadding={customPadding}>
            <V3ModalChildrenWrapper
                ref={ref}
                onScroll={onScroll}
                style={style}
            >
                <VerticalSpacing bottom={hasTitle ? theme.v3.spacings.spacing6 : 0}>
                    <V3ModalTitleWrapper $customPadding={customPadding}>
                        <div>
                            {hasTitle && <V3Title>{title}</V3Title>}
                        </div>
                        {
                            !hideCloseCTA && <V3ModalCloseWrapper>
                                <V3IconClose onClick={onClose} />
                            </V3ModalCloseWrapper>
                        }
                    </V3ModalTitleWrapper>
                </VerticalSpacing>
                <div style={{ marginBottom: bottomPanel ? 0 : 32 }}>
                    {children}
                </div>
            </V3ModalChildrenWrapper>
            {!!bottomPanel && <div style={{ marginBottom: 32, paddingTop: 24 }}>{bottomPanel}</div>}
        </V3ModalLayout>
    </StyledModal >
}