import Link from "next/link"
import Head from 'next/head';
import { V3Button } from "../../components/Button"
import { MarketingMenu } from "../../components/MarketingMenu"
import { FadeInContainer } from "../../components/ReusableStyledComponents/FadeIn"
import { UnstyledLink } from "../../components/ReusableStyledComponents/UnstyledLink"
import { V3ParagraphGrey35 } from "../../components/ReusableStyledComponents/V3ParagraphGrey35"
import { V3HeadlineH2 } from "../../components/Typography"
import { V3Footer } from "../../components/V3Footer"
import { VerticalSpacing } from "../../components/VerticalSpacing"
import Layout from "../../layout/Layout"
import { APPLICATION_ROUTES } from "../../types/routes"
import { ErrorContentLayout, ErrorPageContent } from "./GenericErrorPage.styles"
import { UNAVAILABLE_RESOURCE_TYPE } from "../../types/unavailable_resource_type"
import { getUnavailableContentBodyCopy } from "./GenericErroPage.utils"
import { useRouter } from "next/router";

interface GenericErrorPageProps {
    title: string,
    description: string | JSX.Element,
    showBacktoAuthoryButton?: boolean
    skipUnavailableMetaDescription?: boolean
}

export const GenericErrorPage = ({ title, description, showBacktoAuthoryButton = true, skipUnavailableMetaDescription = false }: GenericErrorPageProps) => {

    const router = useRouter();

    return <Layout title={title}>
        <FadeInContainer>
            <ErrorContentLayout>
                <div>
                    <Head>
                        <link rel="canonical" href={`${process.env.NEXT_PUBLIC_FRONTEND_URL}${router.asPath}`} />
                        {!skipUnavailableMetaDescription && <meta name="og:description" content={"This page is currently not available. Discover Authory for yourself and create a free account now."} />}
                    </Head>
                    <MarketingMenu />
                    <VerticalSpacing top={120}>
                        <ErrorPageContent>
                            <V3HeadlineH2>{title}</V3HeadlineH2>
                            <VerticalSpacing top={12}>
                                <V3ParagraphGrey35>{description}</V3ParagraphGrey35>
                            </VerticalSpacing>
                            {
                                showBacktoAuthoryButton && <VerticalSpacing top={48}>
                                    <Link passHref href={APPLICATION_ROUTES.HOMEPAGE} legacyBehavior>
                                        <UnstyledLink>
                                            <V3Button
                                                text="Back to Authory"
                                                autoWidth
                                                minWidth={150}
                                            />
                                        </UnstyledLink>
                                    </Link>
                                </VerticalSpacing>
                            }
                        </ErrorPageContent>
                    </VerticalSpacing>
                </div>
                <div>
                    <V3Footer maxWidth={"1200px"} />
                </div>
            </ErrorContentLayout>
        </FadeInContainer>
    </Layout>
}

export const Generic404 = () => <GenericErrorPage
    title="Page not found"
    description="We can't find the page you are looking for. Sorry about that!"
    skipUnavailableMetaDescription
/>

export const Generic500 = () => <GenericErrorPage
    title="Technical issue"
    description="We are currently experiencing a technical issue. Our team has been notified and is working on a solution already. Sorry for the inconvenience, we'll be back as soon as possible."
    skipUnavailableMetaDescription
/>

interface Generic403Props {
    target?: UNAVAILABLE_RESOURCE_TYPE
}

export const Generic403 = ({ target }: Generic403Props) => {

    const body = getUnavailableContentBodyCopy(target);

    return <GenericErrorPage
        title="Content not publicly available"
        skipUnavailableMetaDescription
        description={<>
            <Head>
                <meta name="og:description" content={"This page has not been made public yet. Discover Authory for yourself and create a free account now."} />
            </Head>
            {body}
        </>}
        showBacktoAuthoryButton={false}
    />
}